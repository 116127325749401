const R = 6371;
function Path(points) {
  this.points = points;
  this.order = new Array(points.length);
  for (var i = 0; i < points.length; i++) this.order[i] = {index: i, data: points[i].data};
  this.order = this.order.sort((a, b) => {
    if (a.data.type == 'tk') return -1;
    if (b.data.type == 'dl') return 1;
    return 0;
  });
  this.distances = new Array(points.length * points.length);
  for (var i = 0; i < points.length; i++)
    for (var j = 0; j < points.length; j++)
      this.distances[j + i * points.length] = distance(points[i], points[j]);
}
Path.prototype.change = function (temp) {
  var i = 0, j = 0;
  
  do {
    i = this.randomPos();
  } while(i == 0);
  
  do {
    j = this.randomPos();
  } while(j == 0);

  var tkInOrder = false;
  var delta = this.delta_distance(i, j);
  if (delta < 0 || Math.random() < Math.exp(-delta / temp)) {
    this.swap(i, j);
  }
};
Path.prototype.size = function () {
  var s = 0;
  for (var i = 0; i < this.points.length; i++) {
    s += this.distance(i, ((i + 1) % this.points.length));
  }
  return s;
};
Path.prototype.swap = function (i, j) {
  var tmp = this.order[i];
  this.order[i] = this.order[j];
  this.order[j] = tmp;
};
Path.prototype.delta_distance = function (i, j) {
  var jm1 = this.index(j - 1),
    jp1 = this.index(j + 1),
    im1 = this.index(i - 1),
    ip1 = this.index(i + 1);
  var s =
    this.distance(jm1, i)
    + this.distance(i, jp1)
    + this.distance(im1, j)
    + this.distance(j, ip1)
    - this.distance(im1, i)
    - this.distance(i, ip1)
    - this.distance(jm1, j)
    - this.distance(j, jp1);
  if (jm1 === i || jp1 === i)
    s += 2 * this.distance(i, j);
  return s;
};
Path.prototype.index = function (i) {
  return (i + this.points.length) % this.points.length;
};
Path.prototype.access = function (i) {
  return this.points[this.order[this.index(i)].index];
};
Path.prototype.distance = function (i, j) {
  return this.distances[this.order[i].index * this.points.length + this.order[j].index];
};
Path.prototype.randomPos = function () {
  return 0 + Math.floor(Math.random() * (this.points.length - 1));
};

function solve(points, temp_coeff, callback) {
  var path = new Path(points);
  if (points.length < 2) return path.order;
  if (!temp_coeff)
    temp_coeff = 1 - Math.exp(-10 - Math.min(points.length, 1e6) / 1e5);
  var has_callback = typeof (callback) === "function";

  for (var temperature = 100 * distance(path.access(0), path.access(1));
    temperature > 1e-6;
    temperature *= temp_coeff) {
    path.change(temperature);
    if (has_callback) callback(path);
  }
  return path.order;
};

function Point(lat, lon, data) {
  this.lat = lat;
  this.lon = lon;
  this.data = data;
};

function _toRad(Value) {
  return Value * Math.PI / 180;
}

function distance(p, q) {
  console.log({p,q});
  var dLat = _toRad(q.lat - p.lat);
  var dLon = _toRad(q.lon - p.lon);
  var lat1 = _toRad(p.lat);
  var lat2 = _toRad(q.lat);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

if (typeof module === "object") {
  module.exports = {
    "solve": solve,
    "Point": Point
  };
}
