<script>
  import { onMount, onDestroy } from "svelte";
  import Main from "components/Main.html";
  import MapHelper from "modules/mapHelper.js";
  import pathOptimizer from "modules/pathOptimizer.js";

  let map;
  let wrapper;
  let mapWrapper;
  let googleMap;
  let markers = new Map();
  let distance = 0;
  let duration = 0;
  let mobileMapVisible = true;

  onMount(()=> {
    googleMap = new google.maps.Map(map, {
      center: { lat: 55.752107, lng: 37.622345 },
      zoom: 10,
      maxZoom: 18,
      disableDefaultUI: true
    });
    MapHelper.init({googleMap, markers}, {
      clusterize: false
    });
    MapHelper.pathOptimizer = pathOptimizer;

    // let marker = new google.maps.Marker({
    //   position: { lat: 55.752107, lng: 37.622345 },
    //   map: googleMap,
    //   title: 'Hello World!'
    // });
    
    setTimeout(() => hideMap());
  });

  const hideMap = () => {
    mobileMapVisible = false;
  }
</script>

<div class="uk-width uk-grid uk-grid-collapse wrapper" bind:this="{wrapper}">
  <div class="uk-width-auto tool">
    <Main {MapHelper} {mapWrapper} bind:mobileMapVisible />
  </div>
  <div class="uk-width-expand map-wrapper" class:uk-hidden={!mobileMapVisible} bind:this="{mapWrapper}">
    <button class="uk-button uk-button-primary uk-position-small uk-position-top-left uk-width-small uk-hidden@s" on:click="{hideMap}" style="z-index: 9;">Назад</button>
    <div class="map" bind:this="{map}"></div>
  </div>
</div>

<style>
  .wrapper {
    height: 100%;
  }

  .tool {
    width: 500px !important;
  }

  .map {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 959px){
    .map-wrapper {
      display: block !important;
    }
  }

  @media (max-width: 959px){
    .map-wrapper {
      position: fixed;
      top: 60px; left: 0;
      width: 100vw;
      height: calc(100vh - 60px);
      z-index: 9999;
    }
  }

  :global(.fast-route-body-full-height) {
    height: 100vh !important;
  }
</style>