<script>
    export let isTextarea = true;
    export let id = '';
    export let name = '';
    export let rows = 4;
    export let placeholder = '';
    export let value = '';
    export let elm;
</script>

<style>
    textarea {
        line-height: 2;
        min-height: 35px;
        resize: none;
        font-size: 16px;
        background: #FFFFFF;
        padding-left: 10px;
        box-sizing: border-box;
        border-radius: 5px;
    }
    
    .delete-border {
        border: none;
    }
</style>

<div class="wrapper  uk-width">
    {#if isTextarea}
        <textarea class="uk-textarea delete-border uk-width" name={name} id={id} rows={rows} placeholder={placeholder} bind:this={elm} autocomplete="off" bind:value on:click on:input on:change></textarea>
    {:else}
        <input class="uk-input uk-form-small delete-border uk-width" name={name} id={id} rows={rows} placeholder={placeholder} bind:this={elm} autocomplete="off" bind:value on:click on:input on:change>
    {/if}
</div>