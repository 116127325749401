<script context="module">
    let el;

    export const printPoints = () => {
        if(el){
            const myPrint = window.open('','myPrint','');
            myPrint.document.write(el.innerHTML);
            myPrint.print();
            myPrint.close();
        }
    }
</script>

<script>
    export let points;
</script>

<div bind:this={el} style="display: none;">
    <h3>Маршрут от {moment().format('DD.MM.YYYY HH:mm')}</h3>

    <ol>
        {#each points.sort((a, b) => {
            return a.index - b.index;
        }) as point}
        <li>{point.address}</li>
        {/each}
    </ol>
</div>