<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    import Textarea from "UI/Textarea.html"
    import { onMount } from "svelte";

    export let isTextarea = true;
    export let data = {
        address: null,
        index: null,
        code: null
    };
    export let text = "";
    export let rows = 3;
    export let isMarginBottom = true;
    export let isValidCallback;
    export let placeholder = "";
    const apiKey = "AIzaSyAvmvgCSleLj0_RBUVyOcqMglX7qs19yyk";
    let elm;
    let danger = false;

    isValidCallback = () => {
        if(!data || (!data.lat && !data.lon)){
            UIkit.notification({
                message: `Адрес не определен`,
                status: 'danger',
                pos: 'bottom-right',
                timeout: 5000
            });

            setDanger();
            return false;
        }
        return true;
    };

    function setDanger() {
        danger = true;

        elm.classList.add('uk-animation-shake', 'uk-text-danger');
        setTimeout(() => {
            elm.classList.remove('uk-animation-shake');
        }, 300);
        dispatch('danger');
    }

    function removeDanger() {
        data = {
            address: "",
            index: 0,
            code: ""
        };
        if(elm) elm.classList.remove('uk-animation-shake', 'uk-text-danger');
    }

    onMount(() => {
        let autocomplete = new google.maps.places.Autocomplete(elm);

        autocomplete.addListener('place_changed', function() {
            let place = autocomplete.getPlace();

            data.lat = place.geometry.location.lat();
            data.lon = place.geometry.location.lng();
            data.address = elm.value;
            data.index = 1;
            text = elm.value;
            dispatch('change', data);
        });
    });

    const onChange = () => {
        console.log("onChange");
        setTimeout(() => {
            if(text && text != '') {
                if(!data || (!data.lat && !data.lon)){
                    console.log("address find");
                    fetch(`https://maps.google.com/maps/api/geocode/json?address=${text}&key=${apiKey}`).then(responce => {
                        if (responce.ok) {
                            return responce.json();
                        } else {
                            throw new Error("answer isn't ok!")
                        }
                    }).then(answer => {
                        data.lat = answer.results[0].geometry.location.lat;
                        data.lon = answer.results[0].geometry.location.lng;
                        data.address = answer.results[0].formatted_address;
                        data.index = 1;
                        dispatch('change', data);
                    }).catch((error) => {
                        UIkit.notification({
                            message: 'Адрес не определяется, попробуйте ввести снова!',
                            status: 'danger',
                            pos: 'bottom-right'
                        });
                        text = '';
                    });
                }
            }
        }, 500);
    }

    $: if(data && data.address && !data.lon  && !data.lat) {
        onChange();
    }
</script>

<div
    class:uk-margin-small-bottom="{isMarginBottom}"
    class="uk-width">
    <Textarea placeholder="{placeholder}" rows="{rows}" on:change={onChange} bind:value={text} on:input={removeDanger} {isTextarea} bind:elm />
</div>