<script>
  /**
   * @FastRoute import components
   */
  import List, { refreshDOMIndexes } from 'components/List.html';
  import Tools from 'components/Tools.html';
  import PrintTemplate, { printPoints } from 'components/Print.html';

  /**
   * @FastRoute import UI
   */
  import Container from 'UI/Container.html';
  import Leader from 'UI/Leader.html';
  import { successMsg } from 'UI/Messages.html';

  /**
   * @FastRoute {public} params
   * @param {MapHelper} MapHelper
   * @param {i18n} strings
   */
  export let mapWrapper;
  export let mobileMapVisible = false;
  export let MapHelper;
  export let strings = {
    addPointBtn: 'Добавить точку',
    resetBtn: 'Сбросить'
  }

  /**
   * @FastRoute {private} params
   * @param {int} tempId
   * @param {Object} routeInfo
   * @param {array} points
   * @param {DOM} printEl
   */
  let tempId = 3;
  let routeInfo = { distance: 0, duration: 0, legs: null };
  let points = [{
    address: null, lat: null, lon: null, index: 1, id: 1
  }, {
    address: null, lat: null, lon: null, index: 2, id: 2
  }];
  let printEl;
  let circle = false;
  let firstWrap = false;

  /**
   * @FastRoute route actions
   */
  const calcRoute = (optimize = false) => {
    MapHelper.fastRoute(points, optimize, circle).then(answer => {
      if(optimize && answer.route){
        points = answer.route.map((item, idx) => {
          item.index = idx + 1;
          return item;
        });

        refreshDOMIndexes();
        MapHelper.refreshLabels(points);

        successMsg('Маршрут оптимизирован!');
      }

      routeInfo.distance = answer.distance;
      routeInfo.duration = answer.duration;
      routeInfo.legs = answer.legs;
    });
  }
  const saveRoute = () => {
    console.log('saveRoute');
  }
  const optimize = e => {
    calcRoute(true);
  }

  /**
   * @FastRoute text helpers
   */
  const durationText = duration => {
    let hours = Math.floor(duration / 60 / 60);
    if (hours) duration -= (hours * 60 * 60);
    let minutes = Math.ceil(duration / 60);
    return (hours ? hours + ' ч ' : '') + minutes + ' мин'
  }

  const distanceText = distance => {
    return (distance / 1000).toFixed(1).toString() + ' км'
  }

  /**
   * @FastRoute points actions
   */
  const resetPoints = () => {
    points.map(item => {
      if (item.address) MapHelper.hideOnMap(item);
    });
    
    MapHelper.getDirectionRenderer().setMap(null);

    routeInfo.distance = 0;
    routeInfo.duration = 0;

    points = points.map((item, idx) => {
      if(idx == 0 || idx == 1){
        item.address = null;
        item.lat = null;
        item.lon = null;

        return item;
      }

      return null;
    }).filter(item => item);

    refreshDOMIndexes();
  }
  const addPoint = () => {
    points = [...points, {
      address: null, 
      lat: null, 
      lon: null, 
      index: points.length + 1, 
      id: tempId++ 
    }];

    refreshDOMIndexes();

    setTimeout(() => {
      let inputAll = document.getElementById('fast-route-points-list').getElementsByClassName('uk-input');
      inputAll[inputAll.length - 1].focus();
    })
  }

  /**
   * @FastRoute points listeners
   */
  const addPointListener = event => {
    let point = event.detail;

    MapHelper.showOnMap(point);
    MapHelper.setPosition(point);
    MapHelper.fitBounds(points);
    calcRoute();
  }
  const removePointListener = event => {
    let point = event.detail;

    MapHelper.getDirectionRenderer().setMap(null);
    MapHelper.refreshLabels(points);
    calcRoute();
  }
  const hidePointListener = event => {
    let point = event.detail;

    MapHelper.hideOnMap(point);
  }
  const dragEndPointListener = event => {
    MapHelper.refreshLabels(points);
    calcRoute();
  }
  const showMapToolListener = event => {
    mobileMapVisible = true;
    
    if(!firstWrap) {
      firstWrap = true;
      document.body.appendChild(mapWrapper);
    }

    if (points[0].address && points[1].address) setTimeout(() => MapHelper.fitBounds(points), 300);
  }

  /**
   * @FastRoute other actions
   */
  const downloadFile = () => {
    let text = points.map((point, idx) => { 
        return (idx+1)+'.'+point.address;
    }).join('\n');

    let today = moment().format('DD.MM.YYYY HH:mm');

    download(text, "Маршрут от "+today+".txt", "text/plain");
  }

  const setData = () => {
    let distanceInput = document.querySelector('input[name="fast-route-distance"]');
    if(distanceInput){
      distanceInput.value = routeInfo.distance;
      if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        distanceInput.dispatchEvent(evt);
      } else distanceInput.fireEvent("onchange");
    }

    let durationInput = document.querySelector('input[name="fast-route-duration"]');
    if(durationInput){
      durationInput.value = routeInfo.duration;
      if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        durationInput.dispatchEvent(evt);
      } else durationInput.fireEvent("onchange");
    }
  }

  $: circle, calcRoute();
  $: routeInfo, setData();
</script>

<PrintTemplate {points} />
<Container padding="small">
  <Leader name="Расстояние" value={distanceText(routeInfo.distance)} />
  <Leader name="Время в пути" value={durationText(routeInfo.duration)} />
  
  <div class="uk-width uk-margin-small-top">
    Кругорейс <input type="checkbox" bind:checked={circle}>
  </div>
  
  <List 
    on:add={addPointListener} 
    on:remove={removePointListener}
    on:hide={hidePointListener} 
    on:dragEnd={dragEndPointListener}
    bind:points
    {circle} />

  <div class="uk-flex uk-flex-between uk-margin-small-top">
    <button on:click="{addPoint}" class="uk-button uk-button-text">{strings.addPointBtn}</button>
    <button on:click="{resetPoints}" class="uk-button uk-button-text">{strings.resetBtn}</button>
  </div>

  <Tools 
    optimizeDisabled="{points.length < 3 || points.some(point => !point.address)}"
    saveDisabled="{points.length < 2 || points.some(point => !point.address)}"
    on:download="{downloadFile}" 
    on:save="{saveRoute}"
    on:print="{printPoints}"
    on:optimize="{optimize}"
    on:showMap="{showMapToolListener}"
    bind:mobileMapVisible />
</Container>

<style>
  button {
    text-transform: unset;
    font-size: 1rem;
  }
</style>