<script>
    import { createEventDispatcher } from 'svelte';
    import Spinner from 'UI/Spinner.html';

    export let optimizePromise;
    export let optimizeDisabled = true;
    export let saveDisabled = true;
    export let strings = {
        hint: 'Оптимизация маршрута стремиться сделать максимально эффективный кругорейс. Для использования оптимизатора, должны быть не менее 4-х точек и у всех точек должен быть заполнен адрес',
        load: 'Идет оптимизация маршрута. Пожалуйста, подождите...',
        optimizeBtn: 'Оптимизировать маршрут',
        saveBtn: 'Сохранить маршрут',
        printMenu: 'На печать',
        downloadMenu: 'Скачать',
        saveMenu: 'Сохранить в системе',
        mapBtn: 'Показать на карте',
        fullVersionBtn: 'Полная версия программы'
    }

    let serviceLink = 'https://2012222.ru/map/fast/route';

    const dispatch = createEventDispatcher();

    const optimize = () => {
        dispatch('optimize');
    }

    const download = () => {
        dispatch('download');
    }

    const save = () => {
        dispatch('save');
    }

    const print = () => {
        dispatch('print');
    }

    const showMap = () => {
        dispatch('showMap');
    }
</script>

<div class="uk-margin">
    {#await optimizePromise}
        <div class="uk-width uk-text-center">
            <p>{strings.load}</p>
            <Spinner />
        </div>
    {:then answer}
        <div class="uk-flex uk-flex-middle uk-flex-right">
            <span class="uk-margin-small-right" uk-icon="question" uk-tooltip="title: {strings.hint}; pos: top-left"></span>
            <button on:click="{optimize}" class="uk-button uk-button-secondary" disabled="{optimizeDisabled}">
                {strings.optimizeBtn}
            </button>
        </div>
        <div class="uk-flex uk-flex-middle uk-flex-right uk-margin-small-top uk-hidden@s">
            <button class="uk-button uk-button-link" on:click="{showMap}">
                {strings.mapBtn}
            </button>
        </div>
        <!--<a class="uk-button uk-button-danger uk-width uk-margin-large-top" href="{serviceLink}">
            {strings.fullVersionBtn}
        </a>-->
    {/await}
</div>