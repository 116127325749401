<script>
    export let padding = 'default';
</script>

<div 
    class="uk-background-default"
    class:uk-padding-small="{padding == 'small'}">

    <slot></slot>

</div>