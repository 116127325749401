<script context="module"> 
    let listEl;
    
    export const refreshDOMIndexes = () => {
        return new Promise((resolve,reject) => {
            if (listEl) {
                setTimeout(() => {
                    let list = listEl.querySelectorAll('div[data-point-id]');
                    for (let i = 0; i < list.length; i++) {
                        list[i].textContent = i + 1;
                    }

                    resolve();
                });
            } else {
                reject('listEl undefined!');
            }
        });        
    }
</script>

<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import Point from 'components/Point.html';

    export let dragList;
    export let points;
    export let circle = false;

    const dispatch = createEventDispatcher();

    const create = point => e => {
        point.address = e.detail.address;
        point.lat = e.detail.lat;
        point.lon = e.detail.lon;

        dispatch('add', point);
    }

    const remove = point => () => {
        dispatch('hide', point);

        if (points.length > 2) {
            points = points.filter( item => item.id !== point.id );
        } else {
            point.address = null;
            point.lat = null;
            point.lon = null;
            
        }

        refreshIndexes();
        refreshDOMIndexes();

        dispatch('remove', point);
    }

    const refreshIndexes = () => {
        points = points.map( (point, idx) => {
            point.index = idx + 1;
            return point;
        });
    }

    onMount(()=> {
        Sortable.create(listEl, {
            handle: '.point-handle',
            multidrag: false,
            animation: 150,
            
            onEnd: function (evt) {
                refreshDOMIndexes().then(() => {
                    points = points.map(point => {
                        let elm = document.querySelector(`div[data-point-id='${point.id}']`);
                        point.index = elm.textContent;
                        return point;
                    });

                    dispatch('dragEnd');
                });
            }
        });

        refreshDOMIndexes();
    });
</script>

<ul class="uk-list uk-margin-small-bottom" id="fast-route-points-list" bind:this={listEl}>
    {#each points as item, index (item.id)}
        <li class="uk-flex">
            <Point bind:item {index} on:change={create(item)} on:delete={remove(item)} />
            <div class="uk-flex uk-flex-middle">
                <i class="point-handle" uk-icon="icon: more-vertical; ratio: 1"></i>
            </div>
        </li>
    {/each}
</ul>

{#if circle}
    <div class="uk-flex end-point">
        <Point item={points[0]} end={true} />
    </div>
{/if}

<style>
    .point-handle {
        width: 20px;
        cursor: grab;
    }

    .end-point {
        user-select: none;
        pointer-events: none;
    }
</style>